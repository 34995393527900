<template>
  <div class="payment-balance">
    <div v-if="!isFetching">
      <div class="payment-balance-detail">
        <div>
          <img
            src="@/assets/balance.png"
            class="balance-icon">
        </div>
        <div
          v-if="!enable"
          class="wallet-detail">
          <h3 class="is-size-4 has-text-secondary has-text-weight-bold m-b-2x">
            {{ $t('views.book.coming-soon-title') }}
          </h3>
          <p>{{ $t('views.book.coming-soon-desc') }}</p>
        </div>
        <div v-if="enable">
          <div class="m-b">
            {{ $t('views.book.Total price') }}
          </div>
          <div>{{ $t('views.book.Available balance') }}</div>
        </div>
        <div
          v-if="enable"
          class="has-text-right has-text-primary has-text-weight-bold">
          <div class="m-b">
            {{ totalAmount | currencyObj }}
          </div>
          <div :class="{'has-text-danger':availableBalance.value<=0}">
            {{ availableBalance|currencyObj }}
          </div>
        </div>
      </div>
      <div
        v-if="enable"
        class="has-text-centered">
        <el-button
          :disabled="!allowPay"
          class="button-secondary button-155"
          @click="handleSubmit">
          {{ $t('wallet.button') }}
        </el-button>
        <div
          v-if="walletMessage"
          class="wallet-message">
          <span v-html="walletMessage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Wallet',
  props: {
    enable: {
      type: Boolean,
      default: true
    },
    amount: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: String,
      default: 'USD'
    },
    payType: {
      type: String,
      default: 'online'
    },
    isBulkPayment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', {
      isFetching: state => state.isFetching,
      isSubmitting: state => state.isSubmitting,
      walletInfo: state => state.walletInfo
    }),
    ...mapGetters('bookingPolicy', ['totalAmountAfterAdjustment']),
    totalAmount () {
      return this.isBulkPayment ? this.amount : this.totalAmountAfterAdjustment
    },
    availableBalance () {
      return this.walletInfo.availableBalance ? this.walletInfo.availableBalance : null
    },
    minBalance () {
      return this.walletInfo.minBalance ? { currency: this.walletInfo.minBalance.currency, value: this.walletInfo.minBalance.minBalance } : null
    },
    allowPay () {
      if (!this.minBalance || this.minBalance.value >= 0) {
        if (this.availableBalance.value >= this.totalAmount.value.toFixed(2)) {
          return true
        } else {
          return false
        }
      } else {
        const endingBalance = this.availableBalance.value - this.totalAmount.value.toFixed(2)
        if (this.minBalance.value <= endingBalance) {
          return true
        } else {
          return false
        }
      }
    },
    walletMessage () {
      if (!this.allowPay) {
        if (this.minBalance && this.minBalance.value < 0) {
          return this.$t('wallet.message.negative-warning', { amount: this.$options.filters.currencyObj(this.minBalance) })
        } else {
          return this.$t('wallet.message.warning')
        }
      } else {
        if (this.minBalance && this.minBalance.value < 0) {
          return this.$t('wallet.message.info', { amount: this.$options.filters.currencyObj(this.minBalance) })
        } else {
          return ''
        }
      }
    }
  },
  watch: {
    currency () {
      this.getWalletInfo()
    }
  },
  created () {
    this.getWalletInfo()
  },
  methods: {
    async getWalletInfo () {
      if (this.enable) {
        try {
          this.$store.commit('SHOW_FULLSCREEN_LOADER')
          const payload = {
            currency: this.currency
          }
          await this.$store.dispatch('app/getWalletInfo', payload)
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
        } catch (err) {
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        }
      }
    },
    async handleSubmit () {
      if (this.payType === 'payLater') {
        try {
          this.$store.commit('SHOW_FULLSCREEN_LOADER', 'order')
          await this.$store.dispatch('bulkPayment/bulkPayment', {
            orderReferenceList: this.$route.params.orderReference.split(','),
            paymentType: `wallet${this.currency}`
          })
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$alert('You have successfully paid using wallet.', 'Payment successful', {
            confirmButtonText: this.$t('views.TheHeader.manage-bookings'),
            confirmButtonClass: 'button-secondary',
            callback: action => {
              this.$router.replace({ name: 'my-bookings' })
            }
          })
        } catch (err) {
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        }
      } else {
        try {
          this.$store.commit('SHOW_FULLSCREEN_LOADER', 'order')
          const payload = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`))
          payload.payment.paymentType = `wallet${this.currency}`
          await this.$store.dispatch('bookingPolicy/postBook', payload)
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          if (this.$store.state.bookingPolicy.postBook.nextStep) {
            this.$router.push(this.$store.state.bookingPolicy.postBook.nextStep)
          }
        } catch (err) {
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .balance-icon {
    width: 78px;
  }

  .wallet-detail {
    max-width: 270px;
  }

  .wallet-message {
    margin-top: 15px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

</style>
