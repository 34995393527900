<template>
  <g>
    <circle
      cx="51"
      cy="47"
      r="16"
      fill="#C4C4C4" />
    <path
      d="M64.589 0H5.41096C2.4274 0 0 2.4274 0 5.41096V40.4795C0 43.463 2.4274 45.8904 5.41096 45.8904H27.3288C27.8962 45.8904 28.3562 45.4304 28.3562 44.863C28.3562 44.2956 27.8962 43.8356 27.3288 43.8356H5.41096C3.56041 43.8356 2.05479 42.33 2.05479 40.4795V5.41096C2.05479 3.56041 3.56041 2.05479 5.41096 2.05479H64.589C66.4396 2.05479 67.9452 3.56041 67.9452 5.41096V40.4795C67.9452 41.0468 68.4052 41.5068 68.9726 41.5068C69.54 41.5068 70 41.0468 70 40.4795V5.41096C70 2.4274 67.5726 0 64.589 0Z"
      fill="#4A4A4A" />
    <path
      d="M28.4248 36.1641H8.69878C8.13139 36.1641 7.67139 36.6241 7.67139 37.1915C7.67139 37.7589 8.13139 38.2189 8.69878 38.2189H28.4248C28.9922 38.2189 29.4522 37.7589 29.4522 37.1915C29.4522 36.6241 28.9922 36.1641 28.4248 36.1641Z"
      fill="#4A4A4A" />
    <path
      d="M20.6851 17.4655V13.082C20.6851 11.3069 19.241 9.86279 17.4659 9.86279H10.8906C9.1155 9.86279 7.67139 11.3069 7.67139 13.082V17.4655C7.67139 19.2406 9.1155 20.6847 10.8906 20.6847H17.4659C19.241 20.6847 20.6851 19.2406 20.6851 17.4655ZM9.72618 17.4655V16.3011H10.8906C11.458 16.3011 11.918 15.8411 11.918 15.2738C11.918 14.7064 11.458 14.2464 10.8906 14.2464H9.72618V13.082C9.72618 12.4399 10.2485 11.9176 10.8906 11.9176H13.1508V18.6299H10.8906C10.2485 18.6299 9.72618 18.1076 9.72618 17.4655ZM17.4659 18.6299H15.2056V11.9176H17.4659C18.108 11.9176 18.6303 12.4399 18.6303 13.082V14.2464H17.4659C16.8985 14.2464 16.4385 14.7064 16.4385 15.2738C16.4385 15.8411 16.8985 16.3011 17.4659 16.3011H18.6303V17.4655C18.6303 18.1076 18.108 18.6299 17.4659 18.6299Z"
      fill="#4A4A4A" />
    <path
      d="M7.67139 30.6166C7.67139 31.184 8.13139 31.644 8.69878 31.644C9.26618 31.644 9.72618 31.184 9.72618 30.6166V28.4249C9.72618 27.8575 9.26618 27.3975 8.69878 27.3975C8.13139 27.3975 7.67139 27.8575 7.67139 28.4249V30.6166Z"
      fill="#4A4A4A" />
    <path
      d="M10.959 28.4249V30.6166C10.959 31.184 11.419 31.644 11.9864 31.644C12.5538 31.644 13.0138 31.184 13.0138 30.6166V28.4249C13.0138 27.8575 12.5538 27.3975 11.9864 27.3975C11.419 27.3975 10.959 27.8575 10.959 28.4249Z"
      fill="#4A4A4A" />
    <path
      d="M14.2466 28.4249V30.6166C14.2466 31.184 14.7066 31.644 15.274 31.644C15.8414 31.644 16.3014 31.184 16.3014 30.6166V28.4249C16.3014 27.8575 15.8414 27.3975 15.274 27.3975C14.7066 27.3975 14.2466 27.8575 14.2466 28.4249Z"
      fill="#4A4A4A" />
    <path
      d="M17.5342 28.4249V30.6166C17.5342 31.184 17.9942 31.644 18.5616 31.644C19.129 31.644 19.589 31.184 19.589 30.6166V28.4249C19.589 27.8575 19.129 27.3975 18.5616 27.3975C17.9942 27.3975 17.5342 27.8575 17.5342 28.4249Z"
      fill="#4A4A4A" />
    <path
      d="M23.9728 30.6166V28.4249C23.9728 27.8575 23.5128 27.3975 22.9454 27.3975C22.378 27.3975 21.918 27.8575 21.918 28.4249V30.6166C21.918 31.184 22.378 31.644 22.9454 31.644C23.5128 31.644 23.9728 31.184 23.9728 30.6166Z"
      fill="#4A4A4A" />
    <path
      d="M27.2604 30.6166V28.4249C27.2604 27.8575 26.8004 27.3975 26.233 27.3975C25.6656 27.3975 25.2056 27.8575 25.2056 28.4249V30.6166C25.2056 31.184 25.6656 31.644 26.233 31.644C26.8004 31.644 27.2604 31.184 27.2604 30.6166Z"
      fill="#4A4A4A" />
    <path
      d="M29.5206 27.3975C28.9532 27.3975 28.4932 27.8575 28.4932 28.4249V30.6166C28.4932 31.184 28.9532 31.644 29.5206 31.644C30.088 31.644 30.548 31.184 30.548 30.6166V28.4249C30.548 27.8575 30.088 27.3975 29.5206 27.3975Z"
      fill="#4A4A4A" />
    <path
      d="M33.8356 30.6166V28.4249C33.8356 27.8575 33.3756 27.3975 32.8082 27.3975C32.2408 27.3975 31.7808 27.8575 31.7808 28.4249V30.6166C31.7808 31.184 32.2408 31.644 32.8082 31.644C33.3756 31.644 33.8356 31.184 33.8356 30.6166Z"
      fill="#4A4A4A" />
    <path
      d="M56.9175 20.6847C59.901 20.6847 62.3284 18.2573 62.3284 15.2738C62.3284 12.2902 59.901 9.86279 56.9175 9.86279H50.3421C47.3585 9.86279 44.9312 12.2902 44.9312 15.2738C44.9312 18.2573 47.3585 20.6847 50.3421 20.6847H56.9175ZM46.9859 15.2738C46.9859 13.4232 48.4916 11.9176 50.3421 11.9176H56.9175C58.768 11.9176 60.2736 13.4232 60.2736 15.2738C60.2736 17.1243 58.768 18.6299 56.9175 18.6299H50.3421C48.4916 18.6299 46.9859 17.1243 46.9859 15.2738Z"
      fill="#4A4A4A" />
    <path
      d="M48.1503 27.3975C38.5196 27.3975 30.6846 35.2325 30.6846 44.8632C30.6846 54.4939 38.5196 62.329 48.1503 62.329C57.781 62.329 65.6161 54.4939 65.6161 44.8632C65.6161 35.2325 57.781 27.3975 48.1503 27.3975ZM48.1503 60.2742C39.6527 60.2742 32.7394 53.3609 32.7394 44.8632C32.7394 36.3655 39.6527 29.4523 48.1503 29.4523C56.648 29.4523 63.5613 36.3655 63.5613 44.8632C63.5613 53.3609 56.648 60.2742 48.1503 60.2742Z"
      fill="#4A4A4A" />
    <path
      d="M56.5484 36.465C56.1472 36.0638 55.4966 36.0638 55.0955 36.465L48.1507 43.4098L41.206 36.465C40.8047 36.0638 40.1542 36.0638 39.7531 36.465C39.3518 36.8662 39.3518 37.5168 39.7531 37.9179L46.6979 44.8627L39.7531 51.8075C39.3518 52.2087 39.3518 52.8592 39.7531 53.2603C39.9536 53.4609 40.2166 53.5613 40.4795 53.5613C40.7424 53.5613 41.0054 53.461 41.206 53.2603L48.1507 46.3155L55.0955 53.2603C55.2961 53.4609 55.5591 53.5613 55.822 53.5613C56.0849 53.5613 56.3479 53.461 56.5484 53.2603C56.9497 52.8591 56.9497 52.2085 56.5484 51.8075L49.6036 44.8627L56.5484 37.9179C56.9497 37.5168 56.9497 36.8661 56.5484 36.465Z"
      fill="#4A4A4A" />
  </g>
</template>
<script>
export default {
  name: 'IconPaymentFailed'
}
</script>
