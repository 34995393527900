<template>
  <div>
    <div
      id="dropin"
      ref="adyenDropIn"
      class="m-b-3x" />
    <div v-if="isReady">
      <img
        src="../assets/3ds.jpg"
        width="400"
        class="has-text-centered is-block m-b-3x m-t-3x"
        style="margin: auto">
      <div class="has-text-grey is-size-8 has-text-centered">
        {{ $t('views.book.3ds-info') }}
      </div>
    </div>
    <PpModal v-model="modalVisibility">
      <div class="box">
        <div class="box-content has-text-centered">
          <IconBase
            width="72"
            height="72">
            <IconPaymentFailed />
          </IconBase>
          <h2 class="is-size-5 has-text-danger">
            {{ $t('views.book.PaymentFailedPopUp.title') }}
          </h2>
          <p>{{ $t('views.book.PaymentFailedPopUp.message') }}</p>
          <p>{{ $t('views.book.PaymentFailedPopUp.details', {details: errorMsg}) }}</p>
          <PpGap />
          <el-button
            size="small"
            class="button-secondary m-t-2x"
            style="min-width: 155px"
            @click="modalVisibility = false">
            {{ $t('app.ok') }}
          </el-button>
        </div>
      </div>
    </PpModal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

import IconPaymentFailed from '@/components/icons/IconPaymentFailed'

export default {
  name: 'AdyenPayment',
  components: {
    IconPaymentFailed
  },
  props: {
    amount: {
      type: Object,
      default: () => {}
    },
    payType: {
      type: String,
      default: 'online'
    },
    isBulkPayment: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      authUrl: '',
      modalVisibility: false,
      errorMsg: '',
      orderReference: null,
      dropinElement: undefined,
      isReady: false
    }
  },
  computed: {
    ...mapState('bookingPolicy', {
      searchQuery: state => state.reserveForm.searchQuery,
      paymentMethods: state => state.paymentMethods
    })
  },
  mounted () {
    let interval = null
    let attempt = 0
    const maxAttempt = 5
    this.$nextTick(() => {
      // Thanks to https://jefrydco.id/en/blog/safe-access-vue-refs-undefined/
      interval = setInterval(() => {
        if (this.$refs.adyenDropIn || attempt > maxAttempt) {
          clearInterval(interval)
          this.initDropin()
        }
        attempt++
      }, 100)
    })
  },
  beforeDestroy () {
    if (this.dropinElement) {
      this.dropinElement.unmount()
    }
  },
  methods: {
    getPaymentMethods () {
      const payload = {
        body: {
          checkoutAmount: this.amount.value
        },
        params: {
          nationality: this.searchQuery.sourceMarket,
          currency: this.amount.currency
        }
      }
      return this.$store.dispatch('bookingPolicy/getPaymentMethods', payload)
    },
    async initDropin () {
      await this.getPaymentMethods()
      if (!this.dropinElement) {
        const checkout = await this.createAdyenCheckout()
        const dropinElement = await this.createAdyenElement(checkout)
        this.mountDropinElement(dropinElement)
      }
    },
    async makePayment (data) {
      if (this.isBulkPayment) {
        try {
          const payload = {
            orderReferenceList: this.$route.params.orderReference.split(','),
            previewMode: true,
            paymentType: 'creditcard',
            paymentData: data
          }
          return this.$store.dispatch('bulkPayment/bulkPayment', payload)
        } catch (err) {
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        }
      } else {
        const payload = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`))
        payload.payment = data
        payload.payment.paymentType = 'creditcard'
        if (this.orderReference) {
          payload.orderReference = this.orderReference
        }
        return this.$store.dispatch('bookingPolicy/postBook', payload)
      }
    },
    async createAdyenCheckout () {
      const configuration = {
        locale: this.$store.state.user.locale,
        setStatusAutomatically: false,
        environment: this.paymentMethods.paymentEnv || 'test',
        clientKey: this.paymentMethods.clientKey || process.env.VUE_APP_ADYEN_CLIENT_KEY,
        paymentMethodsResponse: this.paymentMethods.details,
        paymentMethodsConfiguration: {
          card: { // Example optional configuration for Cards
            hasHolderName: true,
            holderNameRequired: true,
            // enableStoreDetails: true,
            hideCVC: false, // Change this to true to hide the CVC field for stored cards
            name: 'Credit or Debit Card'
            // maskSecurityCode: true
          }
        },
        analytics: {
          enabled: false // Set to false to not send analytics data to Adyen.
        },
        onError: (err) => {
          console.log('Error', err)
        },
        onSubmit: (state, dropin) => {
          dropin.setStatus('loading')
          this.makePayment(state.data)
            .then(({ outlets }) => {
              this.orderReference = outlets.orderReference
              // 3DS payment
              if (outlets.details.action) {
                dropin.handleAction(outlets.details.action)
                // Non 3DS payment should be redirect to checkout complete page
              } else if (outlets.nextStep) {
                this.$router.push(outlets.nextStep)
                // payment auth fail, show error message
              } else {
                this.modalVisibility = true
                this.errorMsg = outlets.details.refusalReason
                dropin.setStatus('initial')
              }
            }).catch((err) => {
              if (err.error && err.error.code && err.error.code === 'transaction_limit') {
                this.$confirm(this.$t('dailyTransactionLimit.DailyLimitReachedModal.desc'), this.$t('dailyTransactionLimit.DailyLimitReachedModal.title'), {
                  confirmButtonText: this.$t('dailyTransactionLimit.DailyLimitReachedModal.Ok'),
                  cancelButtonClass: 'is-hidden',
                  confirmButtonClass: 'button-secondary w-150',
                  dangerouslyUseHTMLString: true,
                  customClass: 'dail-limit-modal'
                })
              } else {
                this.modalVisibility = true
                this.errorMsg = err.message || (err.error && err.error.message) || ''
              }
              dropin.setStatus('initial')
            })
        },
        onAdditionalDetails: (state, dropin) => {
          this.makePayment(state.data)
            // Your function calling your server to make a /payments/details request
            .then(({ outlets }) => {
              if (outlets.details.action) {
                dropin.handleAction(outlets.details.action)
              } else if (outlets.nextStep) {
                this.$router.push(outlets.nextStep)
              } else {
                throw Error(outlets.details.message)
              }
            }).catch(error => {
              throw Error(error)
            })
        }
      }

      const adyenCheckout = await AdyenCheckout(configuration)
      return adyenCheckout
    },
    async createAdyenElement (checkout) {
      return new Promise((resolve) => {
        const dropinElement = checkout.create('dropin', {
          showPayButton: true
        })
        resolve(dropinElement)
      })
    },
    mountDropinElement (dropinElement) {
      try {
        dropinElement.mount(this.$refs.adyenDropIn)
      } catch (error) {
        console.error(error)
      } finally {
        this.dropinElement = dropinElement
        this.isReady = true
        this.$emit('ready')
      }
    }
  }
}
</script>

<style scoped>

</style>
